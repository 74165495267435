import {
  Grid,
  GridColumn,
  Text,
  ThemeProvider,
  UnbrandedEducationTheme
} from "@modernatx/ui-kit-react";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import React from "react";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { NavPanelProvider } from "@/hooks/useNavPanel";
import { BlockPageProps } from "@/types/Block";
import { Experience, getExperience } from "@/utils/experience";
import { getIsProduction } from "@/utils/production";
import { getProfileTextIdsAndRemoveUndefinedValues } from "@/utils/profileText";

export const getServerSideProps: GetServerSideProps<{
  content: BlockPageProps | null;
  experience: Experience | null;
  isProduction: boolean;
}> = async ({ req }) => {
  const experience = getExperience(req);
  const isProduction = getIsProduction();

  let content: { default: BlockPageProps } | undefined;
  if (experience === null) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  try {
    content = await import(`../profiles${experience?.profile}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Invalid profile path:", e);
    return {
      notFound: true
    };
  }

  if (!content || (isProduction && !experience?.isProduction)) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }
  return {
    props: {
      content: getProfileTextIdsAndRemoveUndefinedValues(content.default, []),
      experience,
      isProduction
    }
  };
};

const Cmv = ({ content, experience }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { country } = experience || {};
  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!country || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }
  return (
    <ExitModalProvider exitModal={content.exitModal}>
      <NavPanelProvider>
        <ThemeProvider
          theme={{
            ...UnbrandedEducationTheme,
            colorsDark: {
              ...UnbrandedEducationTheme.colorsDark,
              buttonColorSecondary: "#191F2A",
              buttonBorderColorPrimary: "#FFF",
              buttonBorderColorSecondary: "#FFF",
              buttonHoverBorderColorSecondary: "#FFF",
              buttonBackgroundColorPrimary: "#9121A6",
              buttonBackgroundColorSecondary: "#FFF",
              buttonColorPrimary: "#FFF",
              buttonHoverBackgroundColorSecondary: "#01283C"
            }
          }}
          usingStyleInheritance={true}
        >
          <Global
            styles={{
              html: {
                backgroundColor: "background01"
              }
            }}
          />
          <RenderMetadata {...content.metadata} />
          <RenderContent content={content} />
        </ThemeProvider>
      </NavPanelProvider>
    </ExitModalProvider>
  );
};
export default Cmv;
